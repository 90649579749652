import { Section } from "@/components";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import UIkit from "uikit";

const Success = () => {
  useEffect(() => {
    UIkit.notification({
      message: "<span uk-icon='icon: check'></span> Success",
      status: "success",
      timeout: 3000,
    });
  });

  return (
    <Section>
      <div className="uk-container uk-container-small">
        <div className="uk-width-2-3@s">
          <h2 className="uk-h2">Thank You</h2>
          <p>We'll get back to you shortly.</p>
          <Link to="/" className="uk-h4 uk-text-bold uk-margin-large-top">
            <span uk-icon="icon: arrow-left; ratio: 2" />
            Back to Home Page
          </Link>
        </div>
      </div>
    </Section>
  );
};
export default Success;
